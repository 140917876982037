(function () {
  'use strict';

  class LoginCtrl {
    constructor($mdDialog, User, $state, Utils, $mdToast) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.User = User;
      vm.$state = $state;
      vm.$mdToast = $mdToast;
      vm.Utils = Utils;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide() {
      let vm = this;
      vm.$mdDialog.hide();
    }
    login(loginForm) {
      let vm = this;
      if (loginForm.$valid) {
        if (vm.forgot) {
          vm.User.passwordRecovery({user: {email: vm.user.email}})
            .then(()=>{
              vm.message(`Password has been sent to ${vm.user.email}.`);
            })
            .catch((data)=>{
              if (data.status === 403) {
                vm.error = data;
              }
            });
        } else {
          vm.User.login(vm.user)
            .then((response)=>{
              const user = response.user;

              if (!user.has_user_password) {
                vm.$state.go('dashboard.profile');
                return vm.hide();
              }
              if (vm.Utils.roleCheck(['superadmin', 'admin'])) {
                vm.$state.go('dashboard.learning.index');
                return vm.hide();
              }
              if (vm.Utils.roleCheck(['Teacher'])) {
                vm.$state.go('dashboard.schools');
                return vm.hide();
              }
              vm.$state.go('dashboard.index');
              vm.hide();
            })
            .catch((data)=>{
              if (data.status === 403) {
                vm.error = data;
              }
            });
        }
      }
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('LoginCtrl', LoginCtrl);
}());
